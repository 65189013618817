import React, { useContext } from "react";
import {
  Hero, HeroImageContainer, HeroHeader, Button, NewsletterBox, HeroFooter, 
} from "@3beehivetech/components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { DataContext } from "../../contexts/DataContext";
import SectionTwoCol from "../../components/SectionTwoCol/SectionTwoCol";
import Intro from "../../components/Intro_v2/Intro_v2";
import BusinessIconText from "../../components/BusinessIconText/BusinessIconText";
import BusinessCards from "../../components/BusinessCards/BusinessCards";
import BusinessImpactNew from "../../components/BusinessImpactNew/BusinessImpactNew";
import ContactFormPipe from "../../components/ContactFormPipe/ContactFormPipe";
import Meta from "../../components/Meta/Meta";
import useNavigate from "../../hooks/useNavigate";
import useNewsletterListId from "../../hooks/useNewsletterListId";
import { toast } from "react-toastify";
import { StyledTitleHero, StyledSubTitleHero } from "../../commons/Typography";
import Faq from "../../components/Faq/Faq";

const Beenergy = () => {
  const {
    pageImages, pageLabels, labels, languages, 
  } = useContext(DataContext);
  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";

  const imageData = pageImages.find(e => e.path === "/2020/images/business/regali/tarassaco.jpg");
  const { getListId } = useNewsletterListId();
  toast.configure();
  
  return (
    <>
      <Meta />
      <Hero className="apply-typography">
        <HeroImageContainer style={{ position: "relative", minHeight: "500px" }}>
          <GatsbyImage loading="eager" layout="fullWidth" objectFit="cover" objectPosition="right" opacity="1" style={{ "max-height": "750px", "display":"block" }}
            image={getImage(imageData)} alt={imageData.alt} />
          <div style={{
            position: "absolute", zIndex: 1, top: 0, left: 0, width: "100%", background: "linear-gradient(rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0.2) 30%, rgba(255, 255, 255, 0.2) 72%, rgb(255, 255, 255) 100%)", 
          }} />
        </HeroImageContainer>
        <HeroHeader
          title={<StyledTitleHero dangerouslySetInnerHTML={{ __html: pageLabels.hero.title }} />}
          description={<StyledSubTitleHero style={{ paddingTop:"20px" }} dangerouslySetInnerHTML={{ __html:pageLabels.hero.paragraph }}/> }
          variant='transparent'>
          <div className="mt-8">
            <Button onClick={useNavigate("#project")}>{pageLabels.hero.cta}</Button>
          </div>
        </HeroHeader>
        <HeroFooter>
          <NewsletterBox
            title={labels.protect}
            newsletterListId={getListId("business")}
            newsletterButtonText={labels.NEWSLETTER}
            newsletterTitle={labels.footer.newsletterLabel}
            lang={currentLanguageCode}
            inputProps={{ placeholder: "Email" }}
            leftButtonProps={{
              text: labels.DETAILS,
              onClick: useNavigate("/adotta-un-alveare"),
            }}
            onSuccess={() => toast.success("Welcome in 3Bee, Bzz", {
              containerId: "Alert",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 10000,
            })}
            onError={() => toast.error("Error. Contact us on info@3bee.com", {
              containerId: "Alert",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 10000,
            })}
          />
        </HeroFooter>
      </Hero>

      <div style={{ height: "5.5rem" }}></div>

      <SectionTwoCol
        id="project"
        title={pageLabels.project.title}
        subtitle1={pageLabels.project.subtilte}
        cta={pageLabels.project.button}
        rightImage={false}
        topImage={true}
        to="#firstGift"
        image="/2020/images/business/regali/beestrong.jpg"
      />

      <div style={{ height: "10.5rem" }}></div>

      <Intro
        bigText={pageLabels.bees.title}
        subtitle={pageLabels.bees.subtitle}
        textAlign="center"
      />
      <BusinessIconText
        items={[{
          image: "/2020/images/business/regali/defence.svg",
          label: pageLabels.bees.impollination.label,
          text: pageLabels.bees.impollination.text,
        },{
          image: "/2020/images/business/regali/goccia.svg",
          label: pageLabels.bees.ecosystem.label,
          text: pageLabels.bees.ecosystem.text,
        },{
          image: "/2020/images/business/regali/tree.svg",
          label: pageLabels.bees.value.label,
          text: pageLabels.bees.value.text,
        },{
          image: "/2020/images/business/regali/terreno.svg",
          label: pageLabels.bees.bioindicators.label,
          text: pageLabels.bees.bioindicators.text,
        }]}
      />

      <div style={{ height: "8.5rem" }}></div>
      <BusinessCards cards = {pageLabels.cards}/>
      <div style={{ height: "6.5rem" }}></div>

      <SectionTwoCol
        id="firstGift"
        title={pageLabels.adotta.title}
        subtitle1={pageLabels.adotta.subtilte}
        cta={pageLabels.adotta.button}
        rightImage={true}
        topImage={true}
        to="#contacts"
        image="/2020/images/business/regali/certificato_app_pc_miele.jpg"
      />

      <div style={{ height: "5rem" }}></div>

      <SectionTwoCol
        title={pageLabels.degustazione.title}
        subtitle1={pageLabels.degustazione.subtilte}
        cta={pageLabels.degustazione.button}
        cta2={pageLabels.degustazione.button2}
        rightImage={false}
        topImage={true}
        to="#contacts"
        to2="#b2bBox"
        images={["/2020/images/box-3bee/box_3bee_3box.jpg", "/2020/images/box-3bee/box_3bee_all.jpg", "/2020/images/box-3bee/mamma_box_bimbi.png"]}
      />

      <div style={{ height: "5rem" }}></div>

      <SectionTwoCol
        title={pageLabels.albero.title}
        subtitle1={pageLabels.albero.subtilte}
        cta={pageLabels.albero.button}
        rightImage={true}
        topImage={true}
        to="#contacts"
        image="/2020/images/business/regali/cherry.jpg"
      />

      <div style={{ height: "5.5rem" }}></div>

      <Intro
        bigText={pageLabels.benefits.title}
        textAlign="center"
      />
      <BusinessIconText
        items={[{
          image: "/2020/images/business/beenergy/benefits/biodiversity.png",
          label: pageLabels.benefits.biodiversity.label,
          text: pageLabels.benefits.biodiversity.text,
        },{
          image: "/2020/images/business/beenergy/benefits/stakeholder.png",
          label: pageLabels.benefits.stakeholder.label,
          text: pageLabels.benefits.stakeholder.text,
        },{
          image: "/2020/images/business/beenergy/benefits/impact.png",
          label: pageLabels.benefits.impact.label,
          text: pageLabels.benefits.impact.text,
        },{
          image: "/2020/images/business/beenergy/benefits/sdg.png",
          label: pageLabels.benefits.sdg.label,
          text: pageLabels.benefits.sdg.text,
        }]}
      />

      <div style={{ height: "5.5rem" }}></div>

      <SectionTwoCol
        title={pageLabels.method.title}
        subtitle1={pageLabels.method.subtilte}
        cta={pageLabels.method.button}
        rightImage={true}
        topImage={true}
        to="#contacts"
        image="/2020/images/business/regali/3bee-02.jpg"
      />

      <div style={{ height: "3.5rem" }}></div>

      <BusinessImpactNew
        image="/2020/images/business/regali/bee_black.jpg"
        title={pageLabels.impact.title}
        subtitle={pageLabels.impact.subtitle}
        item1={pageLabels.impact.item1}
        item2={pageLabels.impact.item2}
        item3={pageLabels.impact.item3}
        item4={pageLabels.impact.item4}
      />

      <div style={{ height: "3.5rem" }}></div>
      
      <ContactFormPipe />
      <div style={{ height: "3.5rem" }}></div>

      <Faq
        data={pageLabels.faq.dataFile.data}
      />
    </>
  );
};

export default Beenergy;
